export const shoppath = {
  getStatistics: "/shop/index/getStatistics",
  addressList: "/shop/index/addressList",
  saveUserAddress: "/shop/index/saveUserAddress",
  getRecommendList: "/shop/Goods/getRecommendList",
  getGoodsDetail: "/shop/Goods/getGoodsDetail",
  getCommentList: "/shop/Goods/getCommentList",
  applyStore: "/shop/index/applyStore",
  uploadimg: "/shop/index/uploadImg",
  applyConfig: "/shop/index/applyConfig",
  goodsList: "/shop/Goods/goodsList",
  getCateList: "/shop/Goods/getCateList",
  orderList: "shop/Order/orderList",
  orderDetail: "/shop/Order/orderDetail",
  payOrder: "/shop/Order/payOrder",
  createOrder: "/shop/Order/createOrder",
  getCode: "shop/index/getCode",
  getStoreInfo: "shop/index/getStoreInfo",
  provinceList: "/shop/index/provinceList",
  cityList: "/shop/index/cityList",
  districtList: "/shop/index/districtList",
  receiptOrder: "/shop/Order/receiptOrder",
  evaluateOrder: "/shop/Order/evaluateOrder",
  appealOrder: "/shop/Order/appealOrder",
  viewAppeal: "/shop/Order/viewAppeal",
  applyOrder: "/shop/index/applyOrder",
  daiList: "/shop/index/daiList",
    signIn: "/shop/index/signIn",
    getSign: "/shop/index/getSign",
    cnyToAtt: "/shop/index/cnyToAtt",
    pointTodai: "/shop/index/pointTodai",
};
