import { get, post } from "./http";
import { shoppath } from "./shoppath";

export let getStatistics = (p) => post(shoppath.getStatistics, p);
export let addressList = (p) => post(shoppath.addressList, p);
export let saveUserAddress = (p) => post(shoppath.saveUserAddress, p);
export let getRecommendList = (p) => post(shoppath.getRecommendList, p);
export let getGoodsDetail = (p) => post(shoppath.getGoodsDetail, p);
export let getCommentList = (p) => post(shoppath.getCommentList, p);
export let applyStore = (p) => post(shoppath.applyStore, p);
export let uploadimg = (p) => post(shoppath.uploadimg, p);
export let applyConfig = (p) => post(shoppath.applyConfig, p);
export let goodsList = (p) => post(shoppath.goodsList, p);
export let getCateList = (p) => post(shoppath.getCateList, p);
export let orderList = (p) => post(shoppath.orderList, p);
export let orderDetail = (p) => post(shoppath.orderDetail, p);
export let payOrder = (p) => post(shoppath.payOrder, p);
export let createOrder = (p) => post(shoppath.createOrder, p);
export let getCode = (p) => post(shoppath.getCode, p);
export let getStoreInfo = (p) => post(shoppath.getStoreInfo, p);
export let provinceList = (p) => post(shoppath.provinceList, p);
export let cityList = (p) => post(shoppath.cityList, p);
export let districtList = (p) => post(shoppath.districtList, p);
export let receiptOrder = (p) => post(shoppath.receiptOrder, p);
export let evaluateOrder = (p) => post(shoppath.evaluateOrder, p);
export let appealOrder = (p) => post(shoppath.appealOrder, p);
export let viewAppeal = (p) => post(shoppath.viewAppeal, p);
export let applyOrder = (p) => post(shoppath.applyOrder, p);
export let daiList = (p) => post(shoppath.daiList, p);
export let signIn = (p) => post(shoppath.signIn, p);
export let getSign = (p) => post(shoppath.getSign, p);
export let cnyToAtt = (p) => post(shoppath.cnyToAtt, p);
export let pointTodai = (p) => post(shoppath.pointTodai, p);
