export const storepath = {
    storelogin: "/store/login/login",
    resetPwd: "/store/login/resetPwd",
    getCode: "store/login/getCode",
    indexStatistics: "/store/Index/indexStatistics",
    orderList: "/store/index/orderList",
    profitData: "/store/index/profitData",
    profitList: "/store/index/profitList",
    goodsList: "/store/index/goodsList",
    editMarketable: "/store/index/editMarketable",
    addStock: "store/index/addStock",
    deleteGoods: "/store/index/deleteGoods",
    exitStore: "/store/index/exitStore",
    addGoods: '/store/index/addGoods',
    deliverOrder: '/store/index/deliverOrder',
    editStore: 'store/index/editStore',
    viewAppeal:'store/index/viewAppeal',
};
