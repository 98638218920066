<template>
  <div class="header" :style="customstyle">
    <div class="headercontent">
      <img @click="clickBack" class="backicon" :src="require('@/assets/images/mall/mallback.png')" />
      <div :class="colorType?'headertitle':'headertitle1'">{{ titlename }}</div>
    </div>
    <div class="headbody">
      <slot name="headbody"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titlename: {
      type: String,
      default: "返回",
    },
     colorType: {
          type:Boolean,
          default: true,
      },
    customstyle: {
      type: Object,
      default: () => ({ background: "#ebeef2" }),
    },
  },
  methods: {
    clickBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  min-height: 176px;
  background: #ebeef2;
  .headercontent {
    padding: 88px 24px 0px 24px;
    width: 100%;
    display: flex;
    align-items: center;
    .backicon {
      width: 64px;
      height: 64px;
      margin-right: 16px;
    }
    .headertitle {
      color: rgba(0, 0, 0, 1);
      font-size: 32px;
      font-weight: 700;
      font-family: "PingFang SC";
    }
    .headertitle1 {
      color: #00B050;
      font-size: 32px;
      font-weight: 700;
      font-family: "PingFang SC";
    }
  }
}
</style>
