<template>
  <div>
    <MallheaderVue
      :titlename="'发布商品'"
      :customstyle="{ background: '#FFFFFF' }"
    ></MallheaderVue>
    <div class="publishcontent">
      <div class="inputbox">
        <van-field
          label-align="right"
          label-width="60"
          v-model="name"
          label="商品名称"
          placeholder="请输入商品名称"
        />
      </div>
      <div class="inputbox marginT22">
        <van-field
          label-align="right"
          label-width="60"
          v-model="price"
          label="商品价格"
          placeholder="请输入价格(USD)"
        />
      </div>
      <div class="marginT22" style="color: rgba(0, 0, 0, 0.9);
    font-size: 0.32rem;
    font-weight: 700;
    font-family: 'PingFang SC';">
        商品支付方式：
      </div>
      <div class="paytype marginT22">

        <div
                :class="payArr.indexOf('2') != -1  ? 'selecttype' : 'nomaltype'"
                @click="selectPay('2')"
        >
          EPT
        </div>
        <div
                :class="payArr.indexOf('1') != -1  ? 'selecttype' : 'nomaltype'"
                @click="selectPay('1')"
        >
          ATT
        </div>
        <div
                :class="payArr.indexOf('3') != -1 ? 'selecttype' : 'nomaltype'"
                @click="selectPay('3')"
        >
          ARR
        </div>
        <div
                :class="payArr.indexOf('4') != -1 ? 'selecttype' : 'nomaltype'"
                @click="selectPay('4')"
        >
          USDT
        </div>
      </div>
      <div class="headlogo marginT22">
        <div>商品图片</div>
        <div class="uploadbox">
          <van-uploader
            v-model="fileList"
            :max-count="4"
            :max-size="5000 * 1024"
            :after-read="clickUpload"
          >
          </van-uploader>
        </div>
      </div>
      <div class="inputbox marginT22">
        <van-field
          label-align="right"
          label-width="60"
          v-model="freight_price"
          label="商品运费"
          placeholder="请输入商品运费(USD)"
        />
      </div>
      <div class="inputbox marginT22">
        <van-field
          label-align="right"
          label-width="60"
          v-model="reward_total"
          label=" 打赏总额"
          placeholder="请输入打赏总额(枚)"
        />
      </div>
      <div class="inputbox marginT22">
        <van-field
          label-align="right"
          label-width="60"
          v-model="reward_num"
          label="购买打赏"
          placeholder="请输入购买打赏(枚)"
        />
      </div>
      <div class="paytype marginT22">
        <div
          :class="reward_coin_symbol == 'EPT' ? 'selecttype' : 'nomaltype'"
          @click="choiceCoin('EPT')"
        >
          EPT
        </div>
        <div
                :class="reward_coin_symbol == 'ATT' ? 'selecttype' : 'nomaltype'"
                @click="choiceCoin('ATT')"
        >
          ATT
        </div>
        <div
          :class="reward_coin_symbol == 'ARR' ? 'selecttype' : 'nomaltype'"
          @click="choiceCoin('ARR')"
        >
          ARR
        </div>
      </div>
      <div class="inputbox marginT22">
        <van-field
          label-align="right"
          label-width="60"
          v-model="stock"
          label=" 商品库存"
          placeholder="请输入商品库存"
        />
      </div>
      <div class="inputbox marginT22">
        <van-field
          @click="choicetype"
          label-align="right"
          label-width="60"
          readonly
          right-icon="arrow-down"
          v-model="cat_txt"
          label="商品类型"
          placeholder="请选择商品类型"
        />
      </div>
      <div class="textareabox marginT22">
        <van-field
          rows="3"
          label-align="right"
          label-width="60"
          v-model="brief"
          label="商品简介"
          type="textarea"
          placeholder="请输入文字"
        />
      </div>
      <div class="textareabox marginT22">
        <div style="font-weight: 700;font-family: 'PingFang SC'; color: #000000;margin: 10px;font-size: 16px;">商品详情</div>
        <vue-html5-editor  :content="intro"
                           :z-index="1000" :auto-height="true" :show-module-name="false"
                           :height="500"
                           @change="updateData"></vue-html5-editor>
      <!--  <vue-html5-editor
          :content="intro"
          :height="500"
          @change="updateData"
        ></vue-html5-editor>-->
      </div>
    </div>
    <div class="bottomview">
      <div class="publish" @click="clickpublish">申请发布</div>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="cateList"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      >
        <template #option="item">{{ item.name }}</template>
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
import { addGoods } from "@/request/storeapi";
import { getCateList, uploadimg } from "@/request/shopapi";
import MallheaderVue from "../../components/mallheader.vue";
import { Toast } from "vant";

export default {
  components: {
    MallheaderVue,
  },
  data() {
    return {
      showPicker: false,
      name: "",
      price: "",
      brief: "",
      images_str: "",
      cat_txt: "",
      cat_id: "",
      freight_price: "",
      reward_total: "",
      reward_num: "",
      reward_coin_symbol: "EPT",
      stock: "",
      intro: "",
      fileList: [],
      cateList: [],
        payArr: ['1','2','3','4'],
        disable:false,
    };
  },
  activated() {
    this.requestgetCateList();
  },
  methods: {
      selectPay(value) {
          let index = this.payArr.indexOf(value);
          if (index === -1) {
              this.payArr.push(value);
          } else {
              this.payArr.splice(index, 1);
          }
          console.log(this.payArr)
      },
    updateData(e = "") {
      let c1 = e.replace(/<img width="100%"/g, "<img");
      let c2 = c1.replace(/<img/g, '<img width="100%"');
      this.intro = c2;
      console.log(this.intro);
    },

    requestgetCateList() {
      getCateList().then((res) => {
        this.cateList = res.data;
      });
    },
    requestaddGoods() {
          if(this.disable){
              return false;
          }
        this.disable = true;
      const params = {
        name: this.name,
        price: this.price,
        brief: this.brief,
        images_str: this.images_str,
        cat_id: this.cat_id,
        freight_price: this.freight_price,
        reward_total: this.reward_total,
        reward_num: this.reward_num,
        reward_coin_symbol: this.reward_coin_symbol,
        stock: this.stock,
        intro: this.intro,
          buy_type: this.payArr.join(","),
      };
      addGoods(params).then((res) => {
        Toast(res.msg);
        let success = res.success;
          this.disable = false;
        if (success) {
          this.$router.go(-1);
        }
      });
    },
    choiceCoin(cointype) {
      this.reward_coin_symbol = cointype;
    },
    clickUpload(file) {
      file.status = "uploading";
      file.message = "上传中...";
      const fd = new FormData();
      fd.append("file", file.file);
      uploadimg(fd)
        .then((res) => {
          let end = this.fileList.length;
          this.fileList[end - 1].imgurl = res.data.http_url;
          console.log(this.fileList);
          file.status = "done";
        })
        .catch((err) => {
          console.log(err);
          file.status = "failed";
          file.message = "上传失败";
        });
    },
    choicetype() {
      this.showPicker = true;
    },
    onConfirm(value) {
      console.log(value);
      this.cat_txt = value.name;
      this.cat_id = value.id;
      this.showPicker = false;
    },
    clickpublish() {
      for (let img of this.fileList) {
        console.log(img);
        if (img.imgurl) {
          this.images_str = this.images_str + img.imgurl + "|";
        }
      }
      let laststr = this.images_str.substr(this.images_str.length - 1, 1);
      if (laststr === "1") {
        this.images_str = this.images_str.slice(0, this.images_str.length - 1);
      }
      if (this.name.length === 0) {
        Toast("请输入商品名称");
        return;
      }
      if (this.price.length === 0) {
        Toast("请输入商品价格");
        return;
      }
        if (this.payArr.length === 0) {
            Toast("请选择支付方式");
            return;
        }
      if (this.brief.length === 0) {
        Toast("请输入商品简介");
        return;
      }

      if (this.images_str.length === 0) {
        Toast("请上传商品图片");
        return;
      }
      if (this.cat_id.length === 0) {
        Toast("请选择分类ID");
        return;
      }
      if (this.freight_price.length === 0) {
        Toast("请输入邮费");
        return;
      }
      if (this.reward_total.length === 0) {
        Toast("请输入打赏总数量");
        return;
      }
      if (this.reward_num.length === 0) {
        Toast("请输入购买打赏数量");
        return;
      }
      if (this.reward_coin_symbol.length === 0) {
        Toast("请选择打赏资产");
        return;
      }
      if (this.stock.length === 0) {
        Toast("请输入库存");
        return;
      }
      if (this.intro.length === 0) {
        Toast("商品详情");
        return;
      }

      this.requestaddGoods();
    },
  },
};
</script>

<style scoped lang="scss">
.marginT22 {
  margin-top: 22px;
}
.publishcontent {
  min-height: 100vh;
  padding: 0 32px 150px 32px;
  .inputbox {
    width: 686px;
    height: 84px;
    border-radius: 16px;
    opacity: 1;
    border: 2px solid rgba(255, 223, 204, 1);
    background: rgba(250, 247, 245, 1);
    .verode {
      font-size: 24px;
      font-weight: 400;
      font-family: "PingFang SC";
      text-align: left;
      color: rgba(255, 114, 32, 1);
    }
    ::v-deep .van-cell {
      background-color: transparent;
      line-height: 20px;
    }
    ::v-deep .van-cell__title {
      font-weight: 700;
      font-family: "PingFang SC";
      color: #000000;
    }
  }
  .headlogo {
    color: rgba(0, 0, 0, 0.9);
    font-size: 24px;
    font-weight: 700;
    font-family: "PingFang SC";
    line-height: 36px;
    .uploadbox {
      margin-top: 8px;
    }
  }
  .textareabox {
    width: 686px;
    min-height: 156px;
    border-radius: 16px;
    opacity: 1;
    border: 2px solid rgba(255, 223, 204, 1);
    background: rgba(250, 247, 245, 1);
    ::v-deep .van-cell {
      background-color: transparent;
    }
    ::v-deep .van-cell__title {
      font-weight: 700;
      font-family: "PingFang SC";
    }
  }
  .paytype {
    display: flex;
    justify-content: space-between;
    font-size: 28px;
    font-weight: 400;
    font-family: "PingFang SC";
    text-align: center;
    .selecttype {
      width: 150px;
      height: 64px;
      border-radius: 8px;
      opacity: 1;
      border: 2px solid rgba(255, 114, 32, 1);
      background: rgba(255, 247, 242, 1);
      color: rgba(255, 114, 32, 1);
      line-height: 64px;
    }
    .nomaltype {
      width: 150px;
      height: 64px;
      border-radius: 8px;
      opacity: 1;
      background: rgba(250, 247, 245, 1);
      color: rgba(105, 98, 95, 1);
      line-height: 64px;
    }
  }
}

.bottomview {
  position: fixed;
  bottom: 0px;
  width: 750px;
  height: 112px;
  opacity: 1;
  background: rgba(250, 247, 245, 1);
  box-shadow: 0 -8px 8px 0 rgba(80, 29, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;

  .publish {
    width: 478px;
    height: 72px;
    border-radius: 74px;
    opacity: 1;
    background: rgba(255, 114, 32, 1);
    color: rgba(238, 238, 238, 1);
    font-size: 28px;
    font-weight: 700;
    font-family: "PingFang SC";
    text-align: center;
    line-height: 72px;
  }
}
</style>