import { get, post } from "./http";
import { storepath } from "./storepath";

export let storelogin = (p) => post(storepath.storelogin, p);
export let resetPwd = (p) => post(storepath.resetPwd, p);
export let getCode = (p) => post(storepath.getCode, p);
export let indexStatistics = (p) => post(storepath.indexStatistics, p);
export let orderList = (p) => post(storepath.orderList, p);
export let profitList = (p) => post(storepath.profitList, p);
export let profitData = (p) => post(storepath.profitData, p);
export let goodsList = (p) => post(storepath.goodsList, p);
export let editMarketable = (p) => post(storepath.editMarketable, p);
export let addStock = (p) => post(storepath.addStock, p);
export let deleteGoods = (p) => post(storepath.deleteGoods, p);
export let exitStore = (p) => post(storepath.exitStore, p);
export let addGoods = (p) => post(storepath.addGoods, p);
export let deliverOrder = (p) => post(storepath.deliverOrder, p);
export let editStore = (p) => post(storepath.editStore, p);
export let viewAppeal = (p) => post(storepath.viewAppeal, p);
